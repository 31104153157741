import { DateTime } from "luxon"
import { GeocodeResult } from "../resources/responses/types"

export type InspectionSummaryByMonth = {
    report: InspectionReportEntity[]
    failures: FailureReport
}

export type InspectionSummary = {
    itemCount: number
    successCount: number
    failures: FailureReport
}

export type InspectionReportEntity = {
    month: number;
    year: number;
    successPercentage: number;
}

export type User = {
    userId: string
    firstName: string
    lastName: string
}

export type UserWithStatus = User & {
    phoneNumber?: string
    email?: string
    lastSubmissionDate?: string
    sms?: Sms
    emailInvite?: EmailInvite
    lastSubmission?: Record<string, Record<string, Submission>>
    externalId?: string
    usersOwningOrgs?: string[]
}

export type UserUpload = {
    userId: string
    firstName: string
    lastName: string
    phoneNumber?: string
    email?: string
    password?: string
    tenantId: string
    externalId?: string
}

export type Submission = {
    questionSetTitle: string
    submittedAt: Date
    iAuditorSites?: string[]
    isFit?: boolean,
    isAtLeastPartiallyIdentified?: boolean,
    isIdentified?: boolean,
}

export type Sms = {
    status: string
    message: string
    createdOn?: Date
}

export type ImageWithText = {
    imageUrl: string;
    comment?: string;
}

export type EmailInvite = {
    status: string
    message: string
    createdOn?: Date
}

export type UserResponse = {
    pageSize: number
    pageNumber: number
    totalCount: number
    sortedBy: string,
    users: UserWithStatus[]
}

export type UserStatusReponse = {
    totalUsersCount: number
    checkedInUsersCount: number
}

export type FailureReport = Record<string, { label: string, count: number }>

export type ScheduleSummary = {
    name: string
    frequency: string
    previousOccurrenceDate?: string
    nextOccurrenceDate?: string
}

export type Site = {
	id: string
	name: string
    location: Location
}

export type Tenant = {
	tenantId: string
	tenantName: string
    authorizedThirdPartyTenants?: string[]
	sites: Site[]
	subTenants: Tenant[]
    reportExpectations?: string[]
    siteConfig?: SiteConfig
    logoImageName?: string
    satisfactionBySiteId?: Record<string, number>
    externalIdFieldName?: string
    hasSessionTracking?: boolean
}

export type SiteConfig = {
    dashboardConfig: DashboardConfig
    managesSubTenants: boolean
    changeSubTenantText?: string
    activityConfig: ActivityConfig
    hasTraining: boolean
    hasUsers: boolean
    iAuditorLinks: Link[]
    helpdeskLink?: string
    isWetton?: boolean
}

export type ActivityConfig = {
    hasActivity: boolean
    ignoresFaceId: boolean
    ignoresLocation: boolean
    customColumns?: ColumnConfig[]
    userNameColumnTitle?: string
    resultColumnTitle?: string
    successText?: string
    failureText?: string
}

export type ColumnConfig = {
    columnId: string
    columnTitle: string
}

export type Link = {
    label: string
    url: string
}

export type DashboardConfig = {
    hasDashboard: boolean,
    hasTraining: boolean,
    hasQuality: boolean,
    hasHealthAndSafety: boolean,
    hasSchedule: boolean,
    hasCurrentlyOnSite: boolean,
    hasOverdue: boolean,
    quickLinks?: Link[],
    hasCardLinks: boolean,
    hasSurvey: boolean,
}

export type TrainingReport = {
    firstName: string
    lastName: string
    report: TrainingItem[]
    externalId: string
    isDue: string
}

export type TrainingPage = {
    totalCount: number
    users: TrainingReport[]
}

export type TrainingItem = {
    displayName: string
    isDue: boolean
    lastSubmitted?: string
    isSuccess?: boolean
}

export type ActivitySummaryResponse = {
    checkIns: SummaryCounts;
    idVerified: SummaryCounts;
    fitForWork: SummaryCounts;
}

export type SummaryCounts = {
    successful: number;
    total: number;
}

export type Location = {
    latitude: number
	longitude: number
    accuracy?: number
}

export type GeoLocation = Location & {
    accuracy: number;
    name?: string;
    geocodeResult: GeocodeResult;
}

export type FaceResult = {
    timeStamp?: any;
    requestSucceeded: boolean;
    photoContainsFaceConfidence: number;
    faceIsMatchConfidence: number;
    brightness: number;
    sharpness: number;
}

export type OptionChosen = {
    optionText: string;
    isCorrect: boolean;
    triggersFreeText?: boolean;
}

export type OptionsNotChosen = {
    optionText: string;
    isCorrect: boolean;
    triggersFreeText?: boolean;
}

export type QuestionsAnswered = {
    questionText: string;
    questionType: string;
    customColumnId?: string;
    timeStamp: Date;
    location: Location;
    faceResult: FaceResult;
    optionChosen: OptionChosen;
    optionsNotChosen: OptionsNotChosen[];
    freeText?: string;
    travelTime?: string;
    link: string;
    linkWasFollowed?: boolean;
}

export type AnswerSet = {
    answerSetId: string;
    userName: string;
    usersOrgTenantIds?: string[];
    timeStamp: string;
    location: GeoLocation;
    faceResult?: FaceResult;
    questionsAnswered: QuestionsAnswered[];
    questionSetId: string;
    questionSetVersionId: string;
    questionSetTitle: string;
    tenantId: string;
    tenantName?: string;
    extraFaceResults: FaceResult[];
    auth0Id: string;
    isAnonymous: boolean;
    isFit: boolean;
    isIdentified: boolean;
    isAtLeastPartiallyIdentified: boolean;
    userId: string;
    externalUserId: string;
    isFaceIdIgnored: boolean;
    questionSetType?: number
    sessionTrackingId?: string;
    sessionStartedTimestamp?: Date;
    sessionDurationMillis?: number;
    imageWithText?: ImageWithText;
    userPhoneNumber?: string // added for Ainscough tenant
}

export type smsHistory = {
    userId: string
    username: string
    phoneNumber: string
    message: string
    timeStamp: string
}
export type ActivityResponse = {
    response: AnswerSet[]
    totalCount: number
}

export type smsHistoryResponse = {
    response: smsHistory[]
    totalCount: number
  
}

export type OverdueSummary = {
    onTime: number
    overdue: number
}



// export type MenuOption = "Dashboard" | "Recent activity" | "Training records" | "iAuditor links"
//                         | "Quality reports" | "Emergency lighting" | "Check cleans"
//                         | "Provide feedback" | "Manage users"

export type MenuOption = Link & {
    icon?: string
    isMinor: boolean
    isExternal: boolean
}

export const toUrlDate = (dt: DateTime) => {
    const iso = dt.toISODate()
    return iso.replace("+", "%2b")
}
