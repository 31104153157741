import { Authenticated } from 'ra-core'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { TableHeaderRow, Text, TableRow, TableCell, ExpandedTableCell, TertiaryButton } from '../../components/atoms'
import { HeaderCell, TextWithSignal, AdminHeader } from '../../components/molecules'
import { SiteHeader as _SiteHeader, ActivitySummary as _ActivitySummary, AnswerDetails, Pager as _Pager, ChangePassword, ValveSensorDummyStats as _ValveSensorDummyStats, } from '../../components/organisms'
import { Menu as _Menu, menuOptionsForConfig } from '../../components/layouts'
import config from '../../config'
import { useWebGet } from '../../hooks/useWebGet'
import { getFirstName, getLastName, getTenants, logout, usesWettonLogin } from '../../providers/auth.service'
import { Color } from '../../styles/color'
import { ActivityResponse, ActivitySummaryResponse, QuestionsAnswered, Tenant, toUrlDate } from '../../types'
import { displayNameOf } from '../../resources/responses/types'
import { DateTime } from "luxon"
import { useHistory, useLocation } from 'react-router-dom'
import getDisplayForAnswer from '../../providers/answerDisplay'
import downloadAnswerSetReport from '../../providers/answerSets'
import queryString from 'query-string'
import moment from 'moment'
import sortdown from "../../images/sort_down_icon.svg"
import sortup from "../../images/sort_up_icon.svg"
import cameraBlack from "../../images/camera-black.svg"
import camera from "../../images/camera.svg"
import TextField from '@material-ui/core/TextField'
import { IconButton, Button as _Button } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SearchIcon from '@material-ui/icons/Search'
import Modal from '../../components/modal'

const Activity: React.FC = () => {

    const history = useHistory()
    const location = useLocation()
    const tenants = getTenants()

    const { tenantId, siteId } = queryString.parse(location.search)

    if (!tenants?.length) {
        history.push("/login")
    }

    const tenant = tenants?.[0]         // TODO: Proper check for undefined
    const siteConfig = tenant?.siteConfig   // TODO: Type narrowing to avoid needing this check for undefined
    const subTenants = tenant?.subTenants?.length ? tenant.subTenants : undefined
    const tenantsToUse = (siteConfig?.managesSubTenants && subTenants) ? [tenant, ...subTenants] : [tenant]

    const selectedTenant = tenantId ? (tenantsToUse.filter(t => t?.tenantId === tenantId)[0] ?? tenantsToUse[0])
                                    : tenantsToUse[0]

    const isMultiTenantedProject = (selectedTenant?.authorizedThirdPartyTenants?.length || 0)>0

    const externalIdFieldName = selectedTenant?.externalIdFieldName;
    const haveSubTenant = selectedTenant?.subTenants ? selectedTenant?.subTenants?.length > 0 
                                                     : false;

    //TODO:  change this to a new bit of tenant config to override the subtenant column name in activity list
    const isHeathrow = (selectedTenant?.tenantId === "1ed41b6b-7cd3-4e2d-830d-dfe11bcdb11a" ||
    selectedTenant?.tenantId === "c16c8943-fc65-4862-981d-1dac0f75b691" ||  //T1
    selectedTenant?.tenantId === "ab14ad2d-5f16-4b51-9a9a-abfc3b708439" ||  //T2
    selectedTenant?.tenantId === "74163bd7-5fe6-41b6-8e27-9f8c8786137e" ||  //T3
    selectedTenant?.tenantId === "0a388e7e-2ee7-45fe-a00e-7f1ad94701af" ||  //T4
    selectedTenant?.tenantId === "cd6f3118-41b9-460f-9b55-65f88b1670b0")  //T5)

    const isAinscough = (selectedTenant?.tenantId === "3d48eae5-63c1-4d52-aa05-0408c43c7ace" //Ainscough
        || selectedTenant?.tenantId === "2a6e665b-5a6a-42d9-9cc3-17f2d00b8ad6" //Business Unit
        || selectedTenant?.tenantId === "c6d674cb-a4c7-4ae7-befe-efc457f6f059" //Project
        || selectedTenant?.tenantId === "3b2d134f-b6dd-4687-af15-128c04238e9b"  //Team A
        || selectedTenant?.tenantId === "81fec0e8-2baf-40e3-bfcb-8022aea4f86c")  //Team B

    const valveSensorDevicesIsSelected = (selectedTenant?.tenantId === "baf006da-7fc2-49b2-8ebb-ce84b35de0b4")
    const isValveSensor = (tenant?.tenantId === "58f56fa9-f0fd-4a8c-8bdd-43f5398fb33e")
                        || (tenant?.tenantId === "baf006da-7fc2-49b2-8ebb-ce84b35de0b4")

                        const approxThamesWaterCenter = { latitude: 51.5, longitude: -0.7959273}
    const hekSiteCenter = { latitude: 51.4924543, longitude: -0.3101423 }
    const hekSiteMarkers = [
        { latitude: 51.5, longitude: -0.3, color: "blue" },
        { latitude: 51.4924553, longitude: -0.3101423, color: "green" },
        { latitude: 51.495, longitude: -0.315, color: "green" },
        { latitude: 51.494, longitude: -0.325, color: "green" },
        { latitude: 51.491, longitude: -0.326, color: "green" },
        { latitude: 51.488, longitude: -0.323, color: "blue" },
        { latitude: 51.497, longitude: -0.307, color: "red" },
    ]  as { latitude: number, longitude: number, color?: "red" | "green" | "blue" }[]

    if (selectedTenant && isValveSensor) {
        selectedTenant.sites = [
            { id: "10", name: "BARHT & SUHIL", location: approxThamesWaterCenter},
            { id: "1", name: "HEK", location: hekSiteCenter},
            { id: "20", name: "MAIDL & PUTNY", location: approxThamesWaterCenter},
            { id: "30", name: "NORTH WESTERN", location: approxThamesWaterCenter},
        ]
    }

    const selectedSite = selectedTenant?.sites?.filter(s => (s.id === siteId))[0] ?? undefined

    const [ changePasswordIsVisible, setChangePasswordIsVisible ] = React.useState(false)
    const [ startDate, setStartDate ] = React.useState(DateTime.now().minus({days: 30}))
    const [ endDate, setEndDate ] = React.useState(DateTime.now())
    //const [ selectedTenant, setSelectedTenant ] = React.useState(tenantsToUse[0])
    //const [ selectedSite, setSelectedSite ] = React.useState((tenantsToUse?.[0]?.sites?.length ?? 0) > 1 ? undefined : tenantsToUse?.[0]?.sites?.[0])
    const [ openRow, setOpenRow ] = React.useState<number | undefined>(undefined)
    const [ openImage, setOpenImage ] = React.useState<number | undefined>(undefined)
    const [ currentPage, setCurrentPage ] = React.useState(1)
    const [ reportIsError, setReportIsError ] = React.useState(false)
    const [ asc, setAsc ] = React.useState(false)
    const [ sort, setSort ] = React.useState('timeStamp')
    const [ filterText, setFilterText ] = React.useState('')
    const activityConfig = selectedTenant?.siteConfig?.activityConfig
    const numberOfColumns = 6 - (activityConfig?.ignoresLocation ? 1 : 0)
                              - (activityConfig?.ignoresFaceId ? 1 : 0)
                              + (activityConfig?.customColumns?.length ?? 0)
                              + (valveSensorDevicesIsSelected ? 1 : 0)

    const pageSize = 10
    const siteQuery = isValveSensor ? ""
                     : selectedSite ? `siteId=${selectedSite?.id}`
                                    : ""
    const siteQueryAmpersand = "&" + siteQuery
        
    const { //isLoading: summaryIsLoading,
            //errorCode: summaryErrorCode,
            refetch: refetchSummary,
            data: summaryData, } = useWebGet<ActivitySummaryResponse>(`${config.API_URL}/tenants/${selectedTenant?.tenantId}/report/activity-summary?from=${toUrlDate(startDate)}&to=${toUrlDate(endDate.plus({days: 1}))}${siteQueryAmpersand}`,
                                                                      !!selectedTenant)
    const { //isLoading: activityIsLoading,
            //errorCode: activityErrorCode,
            refetch: refetchActivity,
            data: activityData } = useWebGet<ActivityResponse>(`${config.API_URL}/tenants/${selectedTenant?.tenantId}/activity?pagesize=${pageSize}&page=${currentPage}${siteQueryAmpersand}&from=${toUrlDate(startDate)}&to=${toUrlDate(endDate.plus({days: 1}))}&sort=${sort + "," + (asc ? 'asc' : 'desc')}&filterText=${filterText}`,
                                                               !!selectedTenant)

    const haveSessionDurationData = selectedTenant?.hasSessionTracking;


    // const approxMiddleOfSouthEngland = { latitude: 51.5, longitude: -1.9 }

    function setSearchQuery(filterText:string) {
        setFilterText(filterText)
    }    
    const zoomToEngland = 6
    const zoomToThamesWater = 7
    const zoomToValveSensors = 13
    const zoomToSite = 15

    const Icon = styled.img`
    width: 25px;
    height: 25px;
    vertical-align: -7px;
`

    useEffect(() => {
        const timer = setTimeout(() => {
          refetchSummary()
          refetchActivity()
        }, 60 * 1000);

        return () => clearTimeout(timer)
      }, [ refetchActivity, refetchSummary ]);

      function onSortClick(sortby:string) {
        if(sortby === sort){
            setAsc(!asc)
        } else {
            setAsc(true)
        }
        
        setSort(sortby )
      }

    return <Authenticated>

        { siteConfig && tenantsToUse && selectedTenant ? <Wrapper>
            { changePasswordIsVisible && <ChangePassword setIsVisible={setChangePasswordIsVisible}/> }
            <Menu options={menuOptionsForConfig(siteConfig)} selectedOption="Recent activity"/>
            <Content>
                <AdminHeader logoImageName={tenant?.logoImageName} firstName={getFirstName() ?? ""} lastName={getLastName() ?? ""} setChangePasswordIsVisible={setChangePasswordIsVisible} logout={() => { const usesWetton = usesWettonLogin(); logout(); history.push(usesWetton ? "/wetton" : "/login")}}/>
                <SiteHeader tenants={tenantsToUse as Tenant[]}
                            selectedTenant={selectedTenant}
                            changeTenantText={siteConfig.changeSubTenantText ?? "Change dashboard"}
                            setSelectedTenant={t => history.push(location.pathname + "?tenantId=" + t.tenantId)}
                            selectedSite={selectedSite}
                            setSelectedSite={s => history.push(location.pathname + "?tenantId=" + selectedTenant.tenantId + (s ? "&siteId=" + s.id : ""))}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}/>

                {summaryData && !isValveSensor && <ActivitySummary mapCenter={selectedSite?.location ?? approxThamesWaterCenter}
                                                                   mapDefaultCenter={selectedSite?.location ?? approxThamesWaterCenter}
                                                                   mapZoom={selectedSite?.location ? zoomToSite : zoomToEngland}
                                                                   mapMarkers={selectedSite ? [selectedSite.location] : []}
                                                                   fractionFit={(summaryData.fitForWork.total === 0) ? 0 : summaryData.fitForWork.successful / summaryData.fitForWork.total}
                                                                   fractionIdentified={activityConfig?.ignoresFaceId ? undefined
                                                                               : (summaryData.idVerified.total === 0) ? 0
                                                                                                                   : summaryData.idVerified.successful / summaryData.idVerified.total}/>}

                {isValveSensor && <ValveSensorDummyStats mapCenter={selectedSite?.id === "1" ? selectedSite!.location : approxThamesWaterCenter}
                                                         mapDefaultCenter={selectedSite?.id === "1" ? selectedSite!.location : approxThamesWaterCenter}
                                                         mapZoom={selectedSite?.id === "1" ? zoomToValveSensors : zoomToThamesWater}
                                                         mapMarkers={selectedSite?.id === "1" ? hekSiteMarkers : []} />}

                {activityData && <Scroller>
                    <div style={{display: "flex", width: "100%", paddingTop:"7px"}} >
                        <div style={{flex: "4", textAlign:"right"}} >
                            <TextField
                                id="search-bar"
                                className="text"
                                onKeyPress={(e) => {
                                    if(e.key.toString() === 'Enter'){
                                        setSearchQuery((e.target as HTMLInputElement)['value'].toString());
                                    }
                                
                                }}
                                label={ <div style={{display:"flex"}}><div style={{marginTop:"-4px"}}><SearchIcon /></div>Search</div>}
                                variant="outlined"
                                placeholder="Start typing..."
                                size="small"
                                
                                InputProps={{
                                    endAdornment: filterText && (
                                        <IconButton
                                        style={{padding:"0px"}}
                                        onClick={(e) => {
                                            if(document.getElementById('search-bar')) {
                                                (document.getElementById('search-bar') as HTMLInputElement).value = ""
                                            } 
                                            setSearchQuery("")
                                        }}
                                        ><CancelIcon /></IconButton>
                                    )
                                    }}
                                    >
                            </TextField>
                        </div>
                        <div style={{flex: "1"}}>
                            <ReportButtonSection>
                                <ReportButton onClick={async () => {
                                    const isSuccess = await downloadAnswerSetReport(selectedTenant.tenantId, isMultiTenantedProject, startDate, endDate, haveSubTenant, externalIdFieldName, filterText)

                                    if (!isSuccess) {
                                        setReportIsError(true)
                                    }
                                }}>
                                    Download report
                                </ReportButton>
                                {reportIsError && <ErrorText color={Color.grey2}>Error downloading report</ErrorText>}
                            </ReportButtonSection>
                        </div>
                    </div>
                    <Table>
                        <TableHeaderRow>
                                <HeaderCellWithHover className={sort === "userName" ? "bold":"bold"} onClick={() => { onSortClick("userName") }}  > {activityConfig?.userNameColumnTitle ?? "Name"}{ sort === "userName" && <Icon src={asc ? sortup : sortdown }></Icon>}</HeaderCellWithHover>
                                {isAinscough ? <HeaderCell>Mobile Number</HeaderCell> : null}

                                {!isHeathrow && selectedTenant.subTenants && selectedTenant.subTenants.length>0 && <HeaderCell>Tenant Name</HeaderCell>}
                                {isHeathrow && selectedTenant.subTenants && selectedTenant.subTenants.length>0 && <HeaderCell>Terminal</HeaderCell>}

                                {selectedTenant?.externalIdFieldName && <HeaderCellWithHover  onClick={() => { onSortClick("externalUserId") }}>{selectedTenant?.externalIdFieldName}{ sort === "externalUserId" && <Icon src={asc ? sortup : sortdown }></Icon>}</HeaderCellWithHover>}
                                {isMultiTenantedProject && <HeaderCellWithHover onClick={() => { onSortClick("usersOrgTenantIds") }}>Organisation{ sort === "usersOrgTenantIds" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>}
                                {!activityConfig?.ignoresLocation && <HeaderCell>Location</HeaderCell>}
                                {!activityConfig?.ignoresFaceId && <HeaderCellWithHover  onClick={() => { onSortClick("isIdentified") }}>ID verified{ sort === "isIdentified" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>}
                                <HeaderCellWithHover onClick={() => { onSortClick("isFit") }}>{activityConfig?.resultColumnTitle ?? "Fit for Work"}{ sort === "isFit" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>
                                <HeaderCellWithHover onClick={() => { onSortClick("questionSetTitle") }}>Activity{ sort === "questionSetTitle" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>
                                {haveSessionDurationData && !isAinscough && <HeaderCell>Session Duration</HeaderCell>}
                                {isAinscough && <HeaderCell>Availability</HeaderCell>}
                                {activityConfig?.customColumns?.map(c => <HeaderCell>{c.columnTitle}</HeaderCell>)}
                                {valveSensorDevicesIsSelected && <HeaderCell>% Open</HeaderCell>}
                                <HeaderCellWithHover onClick={() => { onSortClick("timeStamp") }}>Time | Date{ sort === "timeStamp" && <Icon src={asc ? sortup : sortdown }/>}</HeaderCellWithHover>
                                {<HeaderCell style={{ textAlign: "center" }}>Photo</HeaderCell>}
                        </TableHeaderRow>
                        <Spacer/>

                        {activityData.response.map((a, i) => {
                            const isOpenRow = (openRow === i)
                            const isOpenImage = (openImage === i)
                            const idSignal = a.isFaceIdIgnored ? "Grey"
                                            : a.isIdentified ? "Green"
                                            : a.isAtLeastPartiallyIdentified ? "Amber"
                                            : "Red"
                            const idText = a.isFaceIdIgnored ? "Not Required"
                            : a.isIdentified ? "Verified"
                            : a.isAtLeastPartiallyIdentified ? "Partially verified"
                                                          : "Not verified"

                            const isOpen = valveSensorDevicesIsSelected && isValveSensorOpen(a.questionsAnswered)
                            const isFault = valveSensorDevicesIsSelected && isValveSensorFault(a.questionsAnswered)
                            const isToday = (moment(a.timeStamp).dayOfYear() === moment().dayOfYear())
                                         && (moment(a.timeStamp).year() === moment().year())

                            const isMockPlanned = valveSensorDevicesIsSelected
                                               && !isToday
                                               && !a.isFit
                                               && ((Number(a.timeStamp[a.timeStamp.length - 2]) % 2) === 0)

                            const isFitSignal = isOpen ? "Red"
                                             : isFault ? "Amber"
                                       : isMockPlanned ? "Blue"
                                             : a.isFit ? "Green"
                                                       : "Red"

                            const isFitText = isOpen ? "Open"
                                           : isFault ? "Fault"
                                     : isMockPlanned ? "Planned"
                                           : a.isFit ? activityConfig?.successText ?? "Successful"
                                                     : activityConfig?.failureText ?? "Unsuccessful"

                            const pinColor = valveSensorDevicesIsSelected && isOpen ? "red"
                                          : valveSensorDevicesIsSelected && isFault ? "yellow"
                                          : valveSensorDevicesIsSelected && a.isFit ? "green"
                                    : valveSensorDevicesIsSelected && isMockPlanned ? "blue"
                                                     : valveSensorDevicesIsSelected ? "red"
                                                                                    : undefined

                            let sessionDurationString = "";
                            if(a.sessionDurationMillis) {
                                const sessionMinutes = Math.floor(a.sessionDurationMillis / 60000);
                                const hours = Math.floor(sessionMinutes/60);
                                const minutesRemainder = sessionMinutes % 60;
                                if(hours===0) {
                                    sessionDurationString = `${minutesRemainder}mins`;
                                } else if (minutesRemainder===0) {
                                    sessionDurationString = `${hours}hrs`;
                                } else {
                                    sessionDurationString = `${hours}hrs ${minutesRemainder}mins`;
                                }
                            }

                            return <>
                                <TableRowWithHover onClick={e => isOpenRow ? setOpenRow(undefined) : setOpenRow(i)}>
                                    <TableCell><Text>{a.userName}</Text></TableCell>
                                    {isAinscough ? <TableCell><Text>{a.userPhoneNumber}</Text></TableCell> : null}
                                    {selectedTenant.subTenants && selectedTenant.subTenants.length>0 && <TableCell><Text>{tenantsToUse.map((t) => t?.tenantId === a.tenantId && t.tenantName)}</Text></TableCell>}
                                    {selectedTenant?.externalIdFieldName && <TableCell><Text>{a.externalUserId ?? "-"}</Text></TableCell>}
                                    {isMultiTenantedProject && <TableCell><Text>{a.usersOrgTenantIds}</Text></TableCell>}

                                    {isHeathrow && !activityConfig?.ignoresLocation && <TableCell><Text weight="light">Not Required</Text></TableCell>}
                                    {!isHeathrow && !activityConfig?.ignoresLocation && <TableCell><Text weight="light">{displayNameOf(a.location, isValveSensor ? "Fenchurch St" : "Unknown")}</Text></TableCell>}
                                    {!activityConfig?.ignoresFaceId && <TableCell><TextWithSignal signal={idSignal}>{idText}</TextWithSignal></TableCell>}
                                    <TableCell><TextWithSignal signal={isFitSignal}>{isFitText}</TextWithSignal></TableCell>
                                    <TableCell><Underlined weight="light">{a.questionSetTitle}</Underlined></TableCell>
                                    {!isAinscough && haveSessionDurationData && <TableCell><Text>{sessionDurationString}</Text></TableCell>}
                                    {isAinscough && <TableCell><Text>{a.questionsAnswered[0].optionChosen.optionText}</Text></TableCell>}
                                    {activityConfig?.customColumns?.map(c => <TableCell><Text weight="light">{textForCustomColumnId(a.questionsAnswered, c.columnId, isValveSensor)}</Text></TableCell>)}
                                    {valveSensorDevicesIsSelected && <TableCell><Text weight="light">{textForValveSensorPercentOpen(a.questionsAnswered)}</Text></TableCell>}
                                    <TableCell><Text weight="light">{DateTime.fromISO(a.timeStamp).toFormat("HH:mm | d MMM yyyy")}</Text></TableCell>
                                    <TableCell onClick={(e) => {
                                       if(a?.imageWithText?.imageUrl) {
                                            e.stopPropagation();
                                            setOpenRow(undefined);
                                            setOpenImage(i);
                                       }
                                    }} style={{ textAlign: "center" }}><ImageIcon src={a.imageWithText?.imageUrl ? cameraBlack : camera}/></TableCell>
                                </TableRowWithHover>
                                {isOpenRow && <TableRow><ExpandedTableCell colSpan={numberOfColumns}>
                                    <AnswerDetails answerSet={a}
                                                   ignoresFaceId={activityConfig?.ignoresFaceId}
                                                   mockLocation={isValveSensor ? { latitude: 51.4242339, longitude: -0.4071405, } : undefined}
                                                   pinColor={pinColor}
                                                   isValveSensor={isValveSensor}/>
                                </ExpandedTableCell></TableRow>}
                                <Modal open={isOpenImage} onClose={() => setOpenImage(undefined)}>
                                    {isOpenImage && (
                                        <div>
                                            <img 
                                                src={a?.imageWithText?.imageUrl} 
                                                alt="User Preview" 
                                                style={{ width: "100%", height: "400px", borderRadius: "8px" }}
                                            />
                                            <p><br/>{a?.imageWithText?.comment}<br/> <br/></p>
                                            <footer style={{"textAlign":"center"}}>
                                                <Button variant="contained" color="secondary" onClick={() => setOpenImage(undefined)}>
                                                    Close
                                                </Button>
                                            </footer>
                                        </div>
                                    )}
                                </Modal>
                                
                                <Spacer/>
                            </>
                        })}
                    </Table>
                    <Pager currentPage={currentPage} pageSize={pageSize} totalCount={activityData.totalCount} onNextClicked={() => setCurrentPage(currentPage + 1)} onPreviousClicked={() => setCurrentPage(currentPage - 1)}/>
                </Scroller>}
            </Content>
        </Wrapper>: <div></div>}
    </Authenticated>
}

const textForCustomColumnId = (questionsAnswered: QuestionsAnswered[], columnId: string, isValveSensor: boolean = false) => {

    const possibleAnswers = questionsAnswered.filter(qa => (qa.customColumnId === columnId))

    return (possibleAnswers.length === 1) ? getDisplayForAnswer(possibleAnswers[0], isValveSensor).answerText
                                          : ""
}

const textForValveSensorPercentOpen = (questionsAnswered: QuestionsAnswered[]) => {

    const minimumQuestion = questionsAnswered.filter(qa => (qa.questionText === "Minimum rotation"))[0]
    const maximumQuestion = questionsAnswered.filter(qa => (qa.questionText === "Maximum rotation"))[0]
    const currentQuestion = questionsAnswered.filter(qa => (qa.questionText === "Current rotation"))[0]

    if (minimumQuestion && maximumQuestion && currentQuestion) {
        const minimum = Number(minimumQuestion.freeText)
        const maximum = Number(maximumQuestion.freeText)
        const current = Number(currentQuestion.freeText)

        const canContinue = !isNaN(minimum) && !isNaN(maximum) && !isNaN(current)

        if (canContinue) {
            if ((maximum - minimum) === 0) {
                return "-"
            }

            const fraction = ((maximum - current) / (maximum - minimum))
            return `${Math.round(fraction * 100)}%`
        }
    }

    return ""
}

const isValveSensorOpen = (questionsAnswered: QuestionsAnswered[]) => {

    const isOpenQuestion = questionsAnswered.filter(qa => (qa.questionText === "Current rotation is ok?"))[0]
    return (isOpenQuestion && (isOpenQuestion.optionChosen.isCorrect === false))
}

const isValveSensorFault = (questionsAnswered: QuestionsAnswered[]) => {

    const isFaultQuestion = questionsAnswered.filter(qa => (qa.questionText === "Is faulty?"))[0]
    const batteryIsOkQuestion = questionsAnswered.filter(qa => (qa.questionText === "Battery level is ok?"))[0]
    const isTamperedQuestion = questionsAnswered.filter(qa => (qa.questionText === "Has been tampered with?"))[0]

    return (isFaultQuestion && (isFaultQuestion.optionChosen.optionText === "Yes"))
        || (isTamperedQuestion && (isTamperedQuestion.optionChosen.optionText === "Yes"))
        || (batteryIsOkQuestion && (batteryIsOkQuestion.optionChosen.optionText === "No"))
}

export default Activity

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${Color.lightGrey};
`

const Content = styled.div`
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;
`

const Scroller = styled.div`
    overflow: auto;
    width: 100%;
    padding: 0 40px 40px 40px;
    box-sizing: border-box;
`

const SiteHeader = styled(_SiteHeader)`
    min-height: 100px;
    box-sizing: border-box;
`

const Menu = styled(_Menu)`
    flex-shrink: 0;
`

const ActivitySummary = styled(_ActivitySummary)`
    margin: 30px;
`

const Table = styled.table`
    margin-top: 20px;
    width: 100%;
`

const Spacer = styled.tr`
    height: 10px;
`

const TableRowWithHover = styled(TableRow)`
    &:hover {
        cursor: pointer;
    }
`

const HeaderCellWithHover = styled(HeaderCell)`
    cursor: pointer !important;
`


const Underlined = styled(Text)`
    text-decoration: underline;
`

const Pager = styled(_Pager)`
    margin-top: 20px;
`

const ValveSensorDummyStats = styled(_ValveSensorDummyStats)`
    margin: 30px;
`

const ReportButtonSection = styled.div`

`

const ReportButton = styled(TertiaryButton)`
    margin-left: auto;
`

const ErrorText = styled(Text)`
    margin-left: auto;
    margin-top: 10px;
    text-align: right;
`
const ImageIcon = styled.img`
    width: 29px;
    height: 29px;
    vertical-align: -7px;
`
const Button = styled(_Button)`
    &.MuiButton-contained{
        text-transform: inherit;
        background-color: ${Color.black};
        color: ${Color.green};
        cursor: pointer!important;
        &:hover {
            cursor: default;
            background-color: ${Color.black};
        }
        &:disabled {
            background-color: ${Color.green};
            opacity: 0.3;
        }
    }
`